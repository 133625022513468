import "./OrganizationSettings.scss";

import { useEffect, useState } from "react";
import toast from "react-hot-toast";

import Button from "../../../components/Utils/Button/Button";
import {
  StyledInputEmail,
  StyledInputPhone,
  StyledInputText,
} from "../../../components/Utils/StyledInput/StyledInput";
import StyledSwitch from "../../../components/Utils/StyledSwitch/StyledSwitch";
import useUserStore from "../../../stores/UserStore";
import OrganizationType from "../../../types/Organization";
import appFetch from "../../../Utils/Services/Fetch/appFetch";

const OrganizationSettings = () => {
  const [organization, setOrganization] = useState<OrganizationType>({
    organization_id: 0,
    organization_name: "",
    address: "",
    phone: "",
    email: "",
    vat_number: undefined,
    bank_account: "",
    is_vat_subject: false,
  });

  const { setIsVatSubject } = useUserStore();

  useEffect(() => {
    const fetchData = async () => {
      const orgData = await appFetch(`/api/organization/getOrganization`, {
        method: "GET",
      });
      if (orgData && orgData.organization) {
        setOrganization(orgData.organization);
      }
    };

    fetchData();
  }, []);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      const response = await appFetch("/api/organization/updateOrganization", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(organization),
      });

      if (response) {
        // redirect to home page
        toast.success(response.message);
        // set the isVatSubject value in the store
        setIsVatSubject(organization.is_vat_subject ?? false);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  //  in this component, allow user to update organization settings
  return (
    <div className="updateOrganization">
      <form onSubmit={handleSubmit}>
        <StyledInputText
          label="Nom"
          value={organization.organization_name ?? ""}
          setValue={(value: string) =>
            setOrganization({ ...organization, organization_name: value })
          }
          required={true}
        />
        <StyledInputText
          label="Adresse"
          value={organization.address ?? ""}
          setValue={(value: string) =>
            setOrganization({ ...organization, address: value })
          }
          required={true}
        />

        <StyledInputPhone
          label="Téléphone"
          value={organization.phone ?? ""}
          setValue={(value: string) =>
            setOrganization({ ...organization, phone: value })
          }
          required={true}
        />

        <StyledInputEmail
          label="Email"
          value={organization.email ?? ""}
          setValue={(value: string) =>
            setOrganization({ ...organization, email: value })
          }
          required={true}
        />
        <StyledInputText
          label="Numéro de TVA"
          value={organization.vat_number ?? ""}
          setValue={(value: string) =>
            setOrganization({ ...organization, vat_number: value })
          }
          required={true}
        />
        <StyledInputText
          label="Compte bancaire"
          value={organization.bank_account ?? ""}
          setValue={(value: string) =>
            setOrganization({ ...organization, bank_account: value })
          }
          required={true}
        />

        <StyledSwitch
          checked={organization.is_vat_subject ?? false}
          onChange={() =>
            setOrganization({
              ...organization,
              is_vat_subject: !organization.is_vat_subject,
            })
          }
          label="Assujettissement à la TVA"
        />

        <Button type="submit" className="buttonLogin" label="Modifier" />
      </form>
    </div>
  );
};

export default OrganizationSettings;
